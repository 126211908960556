/* eslint-disable react-hooks/exhaustive-deps */
import { client, useAppDispatch, useAppSelector } from 'Config';
import { GETbyID, GETfields } from 'Config/client';
import { useEffect, useState, useCallback } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import styled from 'styled-components';
import '@reach/tabs/styles.css';
import { BiArrowBack, BiDownload, BiFileBlank } from 'react-icons/bi';
import { BsXLg, BsCheckLg, BsZoomIn } from 'react-icons/bs';
import { AssetsLinks } from './components/AssetsLinks';
import { FormatDate, humanFileSize, zeroFill } from 'Helpers';
import { API_URL } from 'Env';
import { selectFieldsLocale } from 'Features/Categories/lib/model';
import defaultImgSrc from 'Assets/nopreview.png';
import { cart } from 'Features/Cart/lib';
import { selectAssetIdsInCart } from 'Features/Cart/lib/selectors';
import { useT } from './translations';
import { categoryStore } from 'Features/Categories';
import Trans from 'Components/Trans';
import AssetPreview from './components/AssetPreview';
import tw from 'twin.macro';
import { authStore } from 'Features/Authentication/lib';
import { IconButton, Modal, Spinner } from 'Components';
import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { AxiosError } from 'axios';
import { useEyeCatcherRoles } from 'Helpers/useEyeCatcherRoles';

const hiddenFields = new Set(['DT_Client']);

export function AssetDetails({
  data,
  onSelect,
}: {
  data: Asset | { category_id: string; id: string; _loading: true };
  onSelect: (n?: Asset | null) => void;
}) {
  const t = useT();
  const userWerbenet = useAppSelector(authStore.selectUserWerbenet);
  const { isApprover } = useEyeCatcherRoles();
  const category = useAppSelector(categoryStore.selectCategory(String(data?.category_id)));
  const allCategoryFields = useAppSelector(categoryStore.selectCategoryFilters(String(data?.category_id) || ''));
  const [categoryFieldsLoaded, setCategoryFieldsLoaded] = useState(false);
  const [options, setOptions] = useState<Record<string, FieldOption[]>>({});
  const [details, setData] = useState<AssetDetails>();
  const [assetFields, setAssetFields] = useState<{ [k: string]: string[] }>();
  const [assetPreview, setAssetPreview] = useState<Asset>();
  const T = useAppSelector(selectFieldsLocale);
  const cartIds = useAppSelector(selectAssetIdsInCart);
  const dispatch = useAppDispatch();

  const hasStateRequireApproval = details?.state.code === 'require_approval';
  const [showDialog, setShowDialog] = useState(false);
  const close = () => setShowDialog(false);
  const [resonForRejection, setResonForRejection] = useState('');
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const closeRejectDialog = () => setShowRejectDialog(false);
  const toastManager = useToasts();
  const approveEyeCatcherMutation = useMutation(client.approveEyeCatcher);
  const rejectEyeCatcherMutation = useMutation(client.rejectEyeCatcher);
  const [hideApprovalBtns, setHideIsApprovalBtns] = useState(false);

  const addToCart = useCallback(
    () => {
      // @ts-ignore
      dispatch(cart.addItems([data]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const removeFromCart = useCallback(() => {
    // @ts-ignore
    dispatch(cart.removeItems([data.id]));
  }, [dispatch]);

  useEffect(() => {
    GETbyID(data.id).then(({ data }) => {
      const fields = {};
      // group array of objects by attribute
      data.fields.forEach((r) => {
        if (fields[r.key]) {
          fields[r.key].push(r.value);
        } else {
          fields[r.key] = [r.value];
        }
      });
      setAssetFields(fields);
      setData(data);
    });
    if (!allCategoryFields || allCategoryFields.length === 0) return;

    setCategoryFieldsLoaded(false);
    GETfields(allCategoryFields?.map((r) => r.key))
      .then(({ data }) => {
        const newOptions = data.reduce((a: Record<string, FieldOption[]>, c) => {
          const optionValues: FieldOption[] = a[c.field_type] ? a[c.field_type].concat(c) : [c];
          return {
            ...a,
            [c.field_type]: optionValues.sort((a, b) => a.sorting_order - b.sorting_order),
          };
        }, {});
        setOptions(newOptions);
      })
      .finally(() => setCategoryFieldsLoaded(true));
  }, [data.id.toString(), hideApprovalBtns]);

  useEffect(() => {
    if (userWerbenet?.role.toLowerCase() === 'user') {
      hiddenFields.add('Sma_Ansprechpartner');
    }
  }, [userWerbenet]);

  const hasExtra = details?.extra_files.length;

  const handleAssetApproval = async () => {
    approveEyeCatcherMutation.mutate(details?.eye_catcher_id, {
      onSuccess: () => {
        setHideIsApprovalBtns(true);
        toastManager.addToast(
          <div role="alert">
            <strong>{t.successMessageOnApprove}</strong>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
      },
      onError: (error: any) => {
        const e: AxiosError = error;
        toastManager.addToast(
          <div role="alert">
            <strong>{e.response?.statusText}</strong>
            <div>{e.message}</div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      },
    });
    close();
  };

  const handleAssetRejection = () => {
    rejectEyeCatcherMutation.mutate(
      { id: details?.eye_catcher_id, message: resonForRejection },
      {
        onSuccess: () => {
          setHideIsApprovalBtns(true);
          toastManager.addToast(
            <div role="alert">
              <strong>{t.succesMessageOnReject}</strong>
            </div>,
            {
              appearance: 'success',
              autoDismiss: true,
            }
          );
        },
      }
    );
    closeRejectDialog();
  };

  return (
    <Container className="flex-1 pl-12 pb-20">
      {assetPreview && <AssetPreview assetPreview={assetPreview} onClose={() => setAssetPreview(undefined)}></AssetPreview>}
      <h2 className="uppercase font-bold relative text-2xl mb-4">
        {t.detailed_view}
        <button title={t.back} aria-label={t.back} className={'absolute text-2xl text-primary-500 right-0 top-2 '} onClick={() => onSelect(null)}>
          <BiArrowBack />
        </button>
      </h2>
      <AssetsLinks
        ids={[data.id]}
        termsOfUse={!!category?.terms_of_use}
        assetDetails={cartIds.has(data.id.toString()) ? 'remove' : 'add'}
        addToCartDisabled={details?.state.code !== 'published'}
        addToCart={() => addToCart()}
        removeFromCart={() => removeFromCart()}
        {...(!('_loading' in data) ? { assetsSelected: [data] } : {})}
      ></AssetsLinks>

      <Tabs>
        <TabList>
          <Tab className="font-bold disabled:text-gray-20">{t.properties}</Tab>
          <Tab className="font-bold disabled:text-gray-20" disabled={!hasExtra}>
            {t.other_file}
          </Tab>
        </TabList>
        {!details || !categoryFieldsLoaded ? (
          <Spinner className="text-primary-500" size={30}></Spinner>
        ) : (
          <TabPanels>
            <TabPanel>
              <div className="w-full flex">
                <aside className="w-1/3 pr-4">
                  <h3 className="break-words font-extrabold text-xl mb-8">{details.name}</h3>
                </aside>
                <div className="w-2/3 pl-4">
                  <h3 className="font-bold pb-2 border-b border-gray-400 mb-8">{`${t.as_nummer}: ${zeroFill(details.as_number, 6)}`}</h3>
                </div>
              </div>
              <div className="w-full flex">
                <aside className="w-1/3 pr-4">
                  {details.file_preview_url && details.file_preview_url.substring(details.file_preview_url.lastIndexOf('.')) === '.mp4' ? (
                    <video controls src={`${API_URL.slice(0, -8)}${details.file_url}`}>
                      <track default kind="captions" />
                    </video>
                  ) : (
                    <ImgContainer>
                      {details?.alternative_image_url || details?.file_preview_url ? (
                        <img
                          src={`${API_URL.slice(0, -8)}${details?.alternative_image_url ?? details?.file_preview_url}`}
                          alt={details.name}
                          className="rounded w-full mb-8 object-contain mr-2"
                        />
                      ) : (
                        <img className="rounded w-full mb-8 object-contain mr-2" src={defaultImgSrc} alt={details?.name} />
                      )}

                      {(!!details.alternative_image_url || !!details.file_preview_url) && (
                        <button
                          className="button-zoom z-20 absolute inset-1/4 m-auto text-primary-500 opacity-0 rounded-lg bg-white"
                          title={t.zoom_image}
                          aria-label={t.zoom_image}
                          onClick={() => setAssetPreview(details)}
                        >
                          <BsZoomIn size={50} />
                        </button>
                      )}
                    </ImgContainer>
                  )}
                  <p className="font-base mb-4">{details.description}</p>

                  <span className="flex flex-row my-2 flex-wrap">
                    <span className="font-medium">{`${t.file_properties}: `}</span>
                    <div className="flex flex-col pl-2">
                      {details.image_properties &&
                        details.image_properties.resolution &&
                        details.image_properties.resolution.x &&
                        details.image_properties.resolution.y && (
                          <div>
                            <span>
                              <Trans id={`resolution-message`} {...{ value: t.details.resolution, unit: details.image_properties.resolution_unit }} />{' '}
                            </span>
                            <span>
                              {details.image_properties.resolution.x} x {details.image_properties.resolution.y}{' '}
                              {details.image_properties.resolution_unit}
                            </span>
                          </div>
                        )}
                      {details.file_preview_url && details.file_preview_url.substring(details.file_preview_url.lastIndexOf('.')) && (
                        <div>
                          <span>{t.details.file_extension} </span>
                          <span>{details.file_preview_url.substring(details.file_preview_url.lastIndexOf('.'))}</span>
                        </div>
                      )}
                      {details.image_properties &&
                        details.image_properties.size &&
                        details.image_properties.size.height &&
                        details.image_properties.size.width && (
                          <div>
                            <span>{t.details.size} </span>
                            <span>
                              {details.image_properties.size.height} x {details.image_properties.size.width}
                            </span>
                          </div>
                        )}
                      {details.file_size && (
                        <div>
                          <span>{t.details.file_size_MB} </span>
                          <span>
                            {(details.file_size / 1048576).toLocaleString('de-DE', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                            MB
                          </span>
                        </div>
                      )}

                      {details.image_properties &&
                        details.image_properties.print_size &&
                        details.image_properties.print_size.height &&
                        details.image_properties.print_size.width && (
                          <div>
                            <span>{t.details.print_size} </span>
                            <span>
                              {details.image_properties.print_size.height} x {details.image_properties.print_size.width} px.
                            </span>
                          </div>
                        )}

                      {details.image_properties && details.image_properties.color_space && (
                        <div>
                          <span>{t.details.color_space} </span>
                          <span>{details.image_properties.color_space.toUpperCase()}</span>
                        </div>
                      )}
                    </div>
                  </span>

                  <span className="block my-2">
                    <span className="font-medium">{`${t.usage_period_start}:  `}</span>
                    <time>{FormatDate(details.usable_from, true)}</time>
                  </span>

                  <span className="block my-2">
                    <span className="font-medium">{`${t.usage_period_end}:  `}</span>
                    <time>{FormatDate(details.usable_to, true)}</time>
                  </span>

                  <span className="block my-2">
                    <span className="font-medium">{`${t.advertising_materials}:  `}</span> {category?.title}/{category?.section}
                  </span>
                </aside>
                {(assetFields || details?.comment) && (
                  <div className="w-2/3 pl-4">
                    {details.eye_catcher_id && (
                      <p className="bg-gray-200 inline-block p-1 border rounded font-bold mb-2">Status: {t.assetStatus[details.state.code]}</p>
                    )}
                    {details?.comment && (
                      <div className="flex flex-wrap pb-4">
                        <span className="min-w-[200px] font-bold">{`${t.note}:  `}</span>
                        <div className="font-normal article ql-snow" style={{ maxWidth: 'calc(100% - 200px)' }}>
                          <div className="ql-editor !px-0" id={`content-article`} dangerouslySetInnerHTML={{ __html: details.comment }} />
                        </div>
                      </div>
                    )}
                    {assetFields && (
                      <>
                        {Object.keys(assetFields)
                          .filter((key) => !hiddenFields.has(key))
                          .map((key) => (
                            <div className="flex flex-wrap pb-4" key={key}>
                              <span className="min-w-[200px] font-bold">{T[key].display_name + ':'}</span>
                              <FieldValues className="font-normal" style={{ maxWidth: 'calc(100% - 200px)' }}>
                                {assetFields[key].map((assetField, index) => {
                                  const fieldWithLink = !!options[key]
                                    ? options[key].find((field) => !!field.link && field.title === assetField)
                                    : undefined;
                                  return fieldWithLink ? (
                                    <a
                                      key={`field-${index}`}
                                      className="field-value underline text-blue-60 hover:text-blue-70"
                                      target="_blank"
                                      title={fieldWithLink.link || fieldWithLink.title}
                                      aria-label={fieldWithLink.link || fieldWithLink.title}
                                      href={fieldWithLink.link || '/'}
                                      rel="noreferrer"
                                    >
                                      {fieldWithLink.title}
                                    </a>
                                  ) : (
                                    <span key={`field-${index}`} className="field-value">
                                      {assetField}
                                    </span>
                                  );
                                })}
                              </FieldValues>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="w-full flex">
                <aside className="w-1/3 pr-4">
                  <h5 className="break-words font-extrabold text-xl mb-8">{details.name}</h5>
                </aside>
                <div className="w-2/3 pl-4">
                  <h5 className="font-bold pb-2 border-b border-gray-400 mb-8">{`${t.as_nummer}: ${zeroFill(details.as_number, 6)}`}</h5>
                </div>
              </div>
              <div className="w-full flex">
                <aside className="w-1/3 pr-4">
                  {details.extra_files.map((file, index) => (
                    <ExtraFile key={`extra_file_${index}`} name={file.name} url={file.url} size={file.size} />
                  ))}
                </aside>
              </div>
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
      {hasStateRequireApproval && isApprover && (
        <div className="flex gap-4">
          <IconButton className="w-40" title={'Approve'} aria-label="Approve" onClick={() => setShowDialog(true)}>
            {t.approve_modal.approve} <BsCheckLg className="ml-2" />
          </IconButton>
          <IconButton
            className="w-40"
            bg="grey"
            border="gray"
            id="button-reject "
            title={t.approve_modal.reject}
            aria-label={t.approve_modal.reject}
            onClick={() => setShowRejectDialog(true)}
          >
            {t.approve_modal.reject} <BsXLg className="ml-2" />
          </IconButton>
        </div>
      )}
      {(showDialog || showRejectDialog) && (
        <Modal
          headerClasses={'text-[20px] font-extrabold'}
          bodyClasses="pt-5 px-1 overflow-auto"
          title={`${showDialog ? t.approve_modal.confirm_approval : t.approve_modal.reject_approval}`}
          visible={!!showDialog || !!showRejectDialog}
          onRequestClose={() => {
            close();
            closeRejectDialog();
          }}
        >
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="img-container relative grow-0 shrink-0 w-[300px] m-auto md:m-0">
              <p className="font-extrabold pb-3 mb-3 border-b border-gray-300">{t.approve_modal.message}</p>
              {showRejectDialog && (
                <div>
                  <p>{t.approve_modal.reason}</p>

                  <TextArea
                    rows={3}
                    placeholder={t.approve_modal.reason_placeholder}
                    name="message"
                    required
                    active={true}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setResonForRejection(e.target.value)}
                  />
                </div>
              )}
              <div className="flex justify-end mt-8 gap-4">
                <IconButton
                  type="button"
                  className="px-2"
                  title={t.approve_modal.confirm}
                  aria-label={t.approve_modal.confirm}
                  {...(showRejectDialog && { disabled: resonForRejection.length === 0 })}
                  onClick={() => {
                    showDialog && handleAssetApproval();
                    showRejectDialog && handleAssetRejection();
                  }}
                >
                  {t.approve_modal.confirm} <BsCheckLg className="ml-2" />
                </IconButton>
                <IconButton
                  className="px-2"
                  bg="grey"
                  border="gray"
                  title={t.approve_modal.cancel}
                  aria-label={t.approve_modal.cancel}
                  onClick={() => {
                    close();
                    closeRejectDialog();
                  }}
                >
                  {t.approve_modal.cancel} <BsXLg className="ml-2" />
                </IconButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Container>
  );
}

function ExtraFile({ name, url, size }: { name?: string; url?: string; size?: number }) {
  const t = useT();
  if (!name || !size) return null;

  return (
    <div className="py-6 flex border-b border-solid border-gray-300">
      <div>
        <BiFileBlank size={34} className="mr-2" />
      </div>
      <div className="mr-6 flex-1">
        <span className="block">{name}</span>
        <span className="text-gray-500">{`${t.details.file_size} ${humanFileSize(size, true)}`}</span>
      </div>
      <div>
        <a
          title={t.details.download}
          aria-label={t.details.download}
          className={`p-4 flex items-center justify-center bg-primary-500 text-white rounded-xl ${
            url === undefined && 'pointer-events-none opacity-50'
          }`}
          {...(url === undefined && { tabIndex: -1 })}
          download={name}
          href={`${API_URL.slice(0, -8)}${url}?disposition=attachment`}
        >
          <BiDownload />
        </a>
      </div>
    </div>
  );
}

const Container = styled.article`
  [data-reach-tab-list] {
    width: 100%;
    position: relative;
    margin-bottom: 1.5em;
    background-color: white;
    column-gap: 1rem;
    row-gap: 0.5rem;

    [data-reach-tab] {
      position: relative;
      text-align: left;

      &::after {
        left: 0px;
        right: 0px;
        width: 100%;
        bottom: 0px;
        content: '';
        display: block;
        position: absolute;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 2px;
        ${tw`bg-gray-20`}
      }

      &:hover {
        ${tw`text-primary-500`}
        &::after {
          ${tw`bg-primary-500`}
        }
      }
    }
    [data-reach-tab]:disabled {
      cursor: text;
      opacity: 1;
      ${tw`text-gray-20`}
      &:hover {
        ${tw`text-gray-20`}
        &::after {
          ${tw`bg-gray-20`}
        }
      }
    }
    [data-selected] {
      ${tw`text-primary-500`}
      &::after {
        height: 4px;
        ${tw`bg-primary-500`}
      }
    }
  }
`;

const ImgContainer = styled.div`
  position: relative;
  .button-zoom {
    width: fit-content;
    height: max-content;
    padding: 10px;
    opacity: 0;

    &:focus,
    &:hover {
      opacity: 0.9 !important;
    }
  }

  &:hover {
    .button-zoom {
      cursor: zoom-in;
      opacity: 0.6;
    }
  }
`;

const FieldValues = styled.p`
  .field-value ~ .field-value::before {
    content: ', ';
  }
`;

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-30 border-[1px] shadow rounded p-2 focus:text-black w-full `,
  active && tw`text-black border-gray-80`, // Then add conditional styles
]);
