import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export const Page: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
  return <PageComponent className={classNames('page main-page pb-8', className)}>{children}</PageComponent>;
};

const PageComponent = styled.div`
  max-width: var(--page-width);
  margin: 0 auto 3rem auto;
`;
