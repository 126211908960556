import { BreadCrumb, Page } from 'Components';
import { Helmet } from 'react-helmet';
import { useT } from './translations';
import { useEffect, useRef } from 'react';
import { WEB_TOOLKIT_URL } from 'Env';

export default function WebToolkitPage() {
  const t = useT();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const frame = iframeRef.current;

    const adjustIframeSize = (height) => {
      if (frame && frame.contentWindow) {
        frame.style.height = height + 'px';
        frame.style.width = '100%';
      }
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.origin === new URL(WEB_TOOLKIT_URL!).origin) {
        if (event.data.height !== undefined) adjustIframeSize(event.data.height);
      } else {
        console.warn('Message received from an unknown source:', event.origin);
      }
    };

    if (frame) {
      // Listen for messages from the iframe
      window.addEventListener('message', handleMessage);

      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  return (
    <>
      <Page key={'page-webToolkit '} className="pb-0">
        <Helmet defer={false}>
          <title>{t.helmetTitle}</title>
        </Helmet>

        <BreadCrumb
          className="mt-4 mb-8"
          links={[
            {
              title: t.breadcrumbHome,
              link: '/',
            },
            {
              title: t.breadcrumbTitle,
              link: '/webtoolkit',
            },
          ]}
        />
        {WEB_TOOLKIT_URL ? (
          <iframe ref={iframeRef} style={{ width: '100%' }} className="h-[calc(100vh-200px)]" src={WEB_TOOLKIT_URL} title={t.title}></iframe>
        ) : (
          <div className="flex flex-col gap-10">
            <div className="flex flex-row gap-4 items-center">
              <h1 className="font-extrabold text-base text-red-50">{t.linkNotDefined}</h1>
            </div>
          </div>
        )}
      </Page>
    </>
  );
}
